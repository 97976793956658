:root {
  height: 100%;
}

body::before {
  content: '';
  position: fixed;
  top: -100vh; /* Extend above */
  left: 0;
  right: 0;
  bottom: -100vh; /* Extend below */
  background: linear-gradient(135deg, #004E92 0%, #000428 100%);
  z-index: -1;
}

html, body, #root {
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background: linear-gradient(135deg, #004E92 0%, #000428 100%) fixed;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  font-size: 16px;
  letter-spacing: -0.011em;
  background: linear-gradient(135deg, #004E92 0%, #000428 100%) fixed !important;
  min-height: 100vh;
  overflow-x: hidden;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  letter-spacing: -0.02em;
  line-height: 1.3;
}
