.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  background: transparent;
  box-shadow: none;
}

.navbar.scrolled {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar .logo {
  background: linear-gradient(135deg, #00f260 0%, #0575e6 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 1.5rem;
}

.navbar.scrolled .logo {
  background: linear-gradient(135deg, #004E92 0%, #000428 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar .nav-links {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.navbar .nav-links a {
  font-family: 'Inter', sans-serif;
  font-size: 0.85rem;
  font-weight: 500;
  letter-spacing: -0.01em;
  padding: 0.5rem 0;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: all 0.3s ease;
  margin-left: 2rem;
}

.navbar.scrolled .nav-links a {
  color: rgba(0, 0, 0, 0.7);
  background: none;
}

.navbar .nav-links a:hover {
  color: rgba(255, 255, 255, 1);
  transform: translateY(-1px);
}

.navbar.scrolled .nav-links a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar .nav-links a {
  color: #fff;
  margin-left: 2rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar.scrolled .nav-links a {
  color: #000;
}

.logo {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 800;
  letter-spacing: -0.02em;
}

.navbar .nav-links a:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
  }

  .navbar .logo {
    font-size: 1.25rem;
  }

  .navbar .nav-links {
    display: none; /* Hide default nav links on mobile */
  }

  /* Add hamburger menu for mobile - you'll need to implement the functionality */
  .mobile-menu-button {
    display: block;
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .navbar.scrolled .mobile-menu-button {
    color: #000;
  }
}