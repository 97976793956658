.hero {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  padding-top: 80px;
  color: #ffffff;
  background: transparent !important;
}

.hero::before {
  content: '';
  position: absolute;
  bottom: -300px;
  right: -300px;
  width: 1000px;
  height: 1000px;
  background: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23FFFFFF' d='M44.7,-76.4C58.8,-69.2,71.8,-59.1,79.6,-45.8C87.4,-32.5,90,-16.3,87.6,-1.4C85.2,13.6,77.7,27.2,69.1,39.4C60.5,51.6,50.7,62.4,38.6,69.7C26.4,77,13.2,80.7,-0.9,82.2C-15,83.7,-30,83,-43.5,77.1C-57,71.2,-69,60.2,-77.1,46.6C-85.2,33,-89.4,16.5,-88.2,0.7C-87,-15.1,-80.4,-30.2,-71.1,-42.6C-61.8,-55,-49.9,-64.7,-36.9,-72.4C-23.8,-80.1,-11.9,-85.8,1.7,-88.7C15.3,-91.6,30.6,-91.7,44.7,-76.4Z' transform='translate(100 100)' /%3E%3C/svg%3E") no-repeat center center;
  background-size: contain;
  opacity: 0.1;
  animation: rotateInPlace 120s linear infinite, pulseOpacity 10s ease-in-out infinite;
  pointer-events: none;
  transform-origin: center center;
}

.shape {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.05);
  animation: float 20s infinite linear;
  backdrop-filter: blur(5px);
}

.shape1 {
  width: 600px;
  height: 600px;
  bottom: -200px;
  right: -200px;
}

.animated-shapes {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0.1;
    top: 0;
    left: 0;
  } 

.hero-content {
  position: relative;
  z-index: 1;
  max-width: min(1200px, 90%);
  margin: 0 auto;
  min-height: calc(90vh - 80px); 
  padding: clamp(1.5rem, 4vh, 3rem) 1rem; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: clamp(1rem, 2vh, 2rem); 
  animation: fadeIn 1s ease-out;
}

.hero-text {
  text-align: center;
  max-width: 700px;
  margin: 1rem auto; /* Reduced from 1.5rem */
}

.hero-text h1 {
  color: #ffffff;
  font-size: clamp(2rem, 4vw, 3.5rem); /* Slightly smaller font size */
  line-height: 1.1;
  margin-bottom: 1rem; /* Reduced from 1.5rem */
  font-weight: 800;
  letter-spacing: -0.03em;
  font-family: 'Plus Jakarta Sans', sans-serif;
  animation: slideUp 0.8s ease-out;
  opacity: 0;
  animation-fill-mode: forwards;
}

.hero-text p {
  font-size: clamp(1.125rem, 2vw, 1.25rem);
  line-height: 1.8; 
  color: rgba(255, 255, 255, 0.9);
  font-weight: 400;
  margin: 2rem auto;
  max-width: 600px;
  letter-spacing: -0.01em;
  animation: slideUp 0.8s ease-out 0.2s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.gradient-text {
  background: linear-gradient(135deg, #00f260 0%, #0575e6 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-family: inherit;
}

.ai-badge {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  letter-spacing: 0.01em;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  background: linear-gradient(
    135deg,
    rgba(0, 242, 96, 0.2) 0%,
    rgba(5, 117, 230, 0.2) 50%,
    rgba(0, 242, 96, 0.2) 100%
  );
  background-size: 200% auto;
  animation: float 6s ease-in-out infinite, shimmer 8s linear infinite;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 
    0 4px 15px rgba(0, 242, 96, 0.1),
    inset 0 0 20px rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.ai-badge::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.2) 50%,
    transparent 100%
  );
  animation: shine 3s infinite;
}

.ai-badge:hover {
  transform: translateY(-1px);
  box-shadow: 
    0 6px 20px rgba(0, 242, 96, 0.15),
    inset 0 0 25px rgba(255, 255, 255, 0.1);
  background-size: 150% auto;
}

.cta-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem; 
  background: transparent !important;
  position: relative;
  z-index: 1;
  animation: slideUp 0.8s ease-out 0.4s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.cta-group {
  display: flex;
  justify-content: center;
  background: transparent !important;
}

.cta-button {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  letter-spacing: -0.01em;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  transition: all 0.3s ease;
  padding: 0.875rem 2rem;
  border-radius: 12px;
  font-size: 1rem;
  background: transparent;
  width: auto;
  min-width: 200px;
}

.cta-button.primary {
  background: linear-gradient(135deg, #00f260 0%, #0575e6 100%);
  color: white;
  font-weight: 600;
  padding: 1rem 2rem;
  border-radius: 50px;
  transition: transform 0.2s;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 242, 96, 0.2);
  animation: buttonPulse 2s infinite;
}

.cta-button.primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 242, 96, 0.3);
}

.cta-button.secondary {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: calc(0.875rem - 1px) calc(2rem - 1px);
}

.cta-button.secondary:hover {
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.4);
}

.cta-note {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  letter-spacing: -0.01em;
  margin-top: 1rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.button-icon, .play-icon {
  font-size: 1.2em;
  transition: transform 0.3s ease;
}

.cta-button:hover .button-icon {
  transform: translateX(4px);
}

.reassurance-tags {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-top: 0.75rem; /* Reduced from 1rem */
  opacity: 0;
  animation: slideUp 0.8s ease-out 0.3s forwards;
}

.reassurance-tag {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Inter', sans-serif;
  transition: all 0.3s ease;
  position: relative;
}

.reassurance-tag:hover {
  transform: translateY(-1px);
}

.reassurance-tag svg {
  width: 16px;
  height: 16px;
  opacity: 0.9;
  transition: transform 0.3s ease;
}

.reassurance-tag:hover svg {
  transform: scale(1.1);
}

@keyframes shimmer {
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
}

@keyframes shine {
  0% {
    left: -100%;
  }
  100% {
    left: 200%;
  }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes rotateInPlace {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes floatCloud {
  from {
    left: -200px;
  }
  to {
    left: 100%;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulseOpacity {
  0%, 100% { opacity: 0.05; }
  50% { opacity: 0.15; }
}

@keyframes buttonPulse {
  0% { box-shadow: 0 4px 12px rgba(0, 242, 96, 0.2); }
  50% { box-shadow: 0 4px 20px rgba(0, 242, 96, 0.4); }
  100% { box-shadow: 0 4px 12px rgba(0, 242, 96, 0.2); }
  /* opacity: 0.03; */
}
/* 
@media (max-width: 768px) {
  .hero-content {
    padding: 3rem 1.5rem;
  }

  .cta-group {
    flex-direction: column;
    width: 100%;
  }

  .cta-button {
    width: 100%;
    justify-content: center;
    padding: 1rem 1.5rem;
  }

  .hero-text h1 br {
    display: none;
  }
  
  .cta-group {
    width: 100%;
    max-width: 300px;
  }
}

@media (min-width: 1600px) {
  .hero-content {
    max-width: 1400px;
  }
} */


/* // ...existing code... */

@media (max-width: 768px) {
  .hero {
    padding-top: 60px;
  }

  .hero-content {
    padding: 2rem 1rem;
    min-height: calc(100vh - 60px);
  }

  .hero-text h1 {
    font-size: clamp(1.75rem, 7vw, 2.5rem);
    margin-bottom: 0.75rem;
  }

  .hero-text p {
    font-size: 1rem;
    margin: 1.5rem auto;
    padding: 0 1rem;
  }

  .ai-badge {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }

  .reassurance-tags {
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;
    margin-top: 1.5rem;
  }

  .reassurance-tag {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }

  .cta-section {
    margin-top: 1.5rem;
    padding: 0 1rem;
  }

  .cta-button {
    padding: 0.75rem 1.5rem;
    font-size: 0.9rem;
    width: 100%;
    justify-content: center;
  }

  .cta-note {
    font-size: 0.8rem;
    text-align: center;
  }

  .shape1 {
    width: 300px;
    height: 300px;
    bottom: -100px;
    right: -100px;
  }
}

@media (max-width: 480px) {
  .hero-text h1 {
    font-size: clamp(1.5rem, 6vw, 2rem);
  }

  .hero-text p {
    font-size: 0.95rem;
  }

  .cta-button {
    padding: 0.7rem 1.25rem;
  }
}