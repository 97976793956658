.App {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, #004E92 0%, #000428 100%) fixed;
  color: #ffffff;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

/* Add this to ensure all content uses the same background */
body {
  background: linear-gradient(135deg, #004E92 0%, #000428 100%) fixed;
}

#root {
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(135deg, #004E92 0%, #000428 100%) fixed;
}
